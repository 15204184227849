import React from 'react';
import './assets/App.css';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import top from './views/page/top';
import price from './views/page/price';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route path="/top" component={top} />
            <Route path="/price" component={price} />
            <Redirect from="/*" to="/top" />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
